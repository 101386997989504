<template>
	<div class="container-register-sleep">
	<SWNavButton
		@click="$router.push({ name: 'login' })" 
		class="btn-nav-ragister"
		:size="'small'"
		>Back</SWNavButton
	>
	<div class="box-register-sleep">
		<div class="img-sleep-register">
			<img src="../../assets/img/sleepNewLogo.png" alt="" />
		</div>

		<div class="title-sleep-register">
			<p>Success</p>
			<p class="subtitle">Your password has been changed.</p>
		</div>

		<form @submit.prevent="submitHandler">
			<div class="register-input">

			<SWButton type="submit" variant="primari" size="big"
				><router-link to="login">Back to login</router-link></SWButton
			>
			</div>
		</form>

		<!-- <router-view></router-view> -->
	</div>
 </div>
 </template>
 
 <script>
 import {SWNavButton, SWButton } from 'sw-ui-kit';

 export default {

 components: {
	SWNavButton,
	SWButton,
 },

 data(){
	return {
		success: false
	}
 },

mounted(){
	console.log(this.$route.params.success)
	this.success = this.$route.params.success;

	if(!this.success){
			this.$router.push({ name: 'forgot' });
		}
},

 };
 </script>

<style lang="sass" scoped>

.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 15px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
	text-align: center

.subtitle
	font-family: 'Roboto', sans-serif
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	color: #11151F
	margin-top: 16px
	margin-bottom: 16px


.register-input
	margin-top: 50px
	@media screen and (max-width:380px)
		margin-bottom: 10px
.btn-nav-ragister
	margin-bottom: 20px
	font-weight: 400
	margin-top: 64px

@media screen and (max-width:1024px)
	.btn-nav-ragister
		margin-top: 16px
</style>